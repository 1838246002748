import React, { useEffect } from "react";
import "./Listingpolicy.css";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsStopCircle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { add } from "../../store/Track/trackUserSlice";

const Refundpolicy = () => {
  const disptach = useDispatch();
  useEffect(() => {
    disptach(add({ view: ["Refundpolicy"] }));
  }, []);
  return (
    <>
      <div
        className="page-title listingpolicy-scroll page-title--small align-left  hidden-sm hidden-xs visible-md-block visible-lg-block"
        style={{
          background: "linear-gradient(rgb(43, 88, 118), rgb(78, 67, 118))",
        }}
      >
        <div className="container">
          <div className="page-title__content">
            <h1 className="page-title__name" style={{ fontFamily: "Roboto" }}>
              Refund and Cancellation Policy
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div>
          <p>
            Hola9 is an online platform that primarily offers classifieds for
            services and products.
            <br />
            The company's refund and cancellation policies may vary depending on
            the type of
            <br />
            transaction (such as buying products, booking services, or posting
            ads). However,
            <br />
            general guidelines about refunds and cancellations on Hola9 include
            the following:
            <br />
          </p>
          <p>
            1. Refund Policy for Paid Services (e.g., Premium Listings,
            <br />
            Ads)
            <br />
          </p>
          <p>
            &#61623; <b>Premium Ads: </b>If you have purchased a paid listing or
            service (like a premium ad), Hola9
            <br />
            generally does not offer refunds for cancellations once the service
            has been activated or the
            <br />
            ad has been published. Refunds may be possible only under special
            circumstances (e.g.,
            <br />
            technical issues or errors).
            <br />
          </p>
          <p>
            &#61623; <b>Subscription Services: </b>If you've subscribed to any
            recurring services or premium features,
            <br />
            and there is an issue with the payment, you might need to contact
            Hola9 support for
            <br />
            resolution. Refunds may be issued in some cases if the service
            wasn&#8217;t provided as promised.
            <br />
          </p>
          <p>
            2. Refund Policy for Products Purchased via Hola9 (if
            <br />
            applicable)
            <br />
          </p>
          <p>
            Hola9 allows users to buy and sell items via its platform, but it
            does not act as a direct
            <br />
            seller. If you&#8217;ve bought a product directly from a seller
            through Hola9, the refund or
            <br />
            return policy will depend on the seller&#8217;s own terms, as Hola9
            does not typically
            <br />
            handle these transactions directly.
            <br />
          </p>
          <p>
            &#61623; <b>Seller Returns &amp; Refunds: </b>You should reach out
            directly to the seller regarding any refunds
            <br />
            or cancellations for products purchased. The return/refund policies
            will vary depending on
            <br />
            the seller&#8217;s guidelines, which are often mentioned in the
            product listing.
            <br />
          </p>
          <p>
            &#61623; <b>Payment through Hola9: </b>If the transaction is done
            via Hola9&#8217;s payment gateway or other
            <br />
            payment methods integrated into the platform, Hola9 may assist with
            the resolution, but
            <br />
            they are not always involved in resolving disputes between buyers
            and sellers.
            <br />
          </p>
          <p>
            3. Cancellation Policy for Paid Ads
            <br />
          </p>
          <p>
            &#61623; <b>Canceling an Ad: </b>If you&#8217;ve paid for a premium
            ad or service, you can usually cancel or modify
            <br />
            the listing. However, cancellations of paid services or ads
            generally don't guarantee a refund,
            <br />
            especially if the ad has already been published or the service has
            been rendered.
            <br />
          </p>
          <p>
            &#61623; <b>Refunds for Cancellations: </b>If you cancel a paid ad
            or service soon after payment but before
            <br />
            publication or usage, Hola9 may offer a partial or full refund,
            depending on the
            <br />
            circumstances.
            <br />
          </p>
          <p>
            4. Cancellation or Refund for Service Listings (Job or
            <br />
            Service Offerings)
            <br />
          </p>
          <p>
            &#61623; <b>Services: </b>If you&#8217;ve booked a service through
            Hola9, the cancellation and refund policies
            <br />
            depend on the service provider. Hola9 typically does not directly
            handle refunds or
            <br />
            cancellations for services unless specified in the listing or terms.
            <br />
          </p>
          <p>
            &#61623; <b>Refunds for Services: </b>If the service provider
            cancels or fails to deliver the service as promised,
            <br />
            you might be entitled to a refund, but this needs to be negotiated
            directly with the service
            <br />
            provider or through Hola9&#8217;s customer support.
          </p>
        </div>
      </div>
      <div>
        <div>
          <p>
            5. Contacting Hola9 Support
            <br />
          </p>
          <p>
            &#61623; If you're facing issues with refunds, cancellations, or
            disputes, you can contact Hola9&#8217;s
            <br />
            customer support team through their official website or app. They
            can provide specific
            <br />
            instructions based on the nature of the transaction.
            <br />
          </p>
          <p>
            Important Notes:
            <br />
          </p>
          <p>
            &#61623; <b>Time Limit for Refunds: </b>Refund requests typically
            have a time limit, so it&#8217;s best to contact
            <br />
            support as soon as you encounter an issue.
            <br />
          </p>
          <p>
            &#61623; <b>Documentation: </b>Keep any proof of payment,
            communication, or transaction details to
            <br />
            support your case if you request a refund.
            <br />
          </p>
          <p>
            For specific details about refunds and cancellations, it&#8217;s
            always a good idea to check
            <br />
            the latest terms and conditions on Hola9&#8217;s website or reach
            out to their customer
            <br />
            support team directly.
          </p>
        </div>
      </div>
    </>
  );
};

export default Refundpolicy;
