import React, { useEffect } from "react";
import "./Listingpolicy.css";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsStopCircle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { add } from "../../store/Track/trackUserSlice";

const Shippingpolicy = () => {
  const disptach = useDispatch();
  useEffect(() => {
    disptach(add({ view: ["Shippingpolicy"] }));
  }, []);
  return (
    <>
      <div
        className="page-title listingpolicy-scroll page-title--small align-left  hidden-sm hidden-xs visible-md-block visible-lg-block"
        style={{
          background: "linear-gradient(rgb(43, 88, 118), rgb(78, 67, 118))",
        }}
      >
        <div className="container">
          <div className="page-title__content">
            <h1 className="page-title__name" style={{ fontFamily: "Roboto" }}>
              Shipping and Delivery Policy
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div>
          <p>
            Hola9, as an online classifieds platform, does not directly handle
            the shipping or
            <br />
            delivery of products in the same way as e-commerce platforms like
            Amazon or
            <br />
            Flipkart. However, the platform facilitates transactions between
            buyers and sellers,
            <br />
            and shipping or delivery responsibilities are typically handled by
            the individual sellers.
            <br />
            That being said, here is a general overview of what to expect
            regarding shipping and
            <br />
            delivery policies when using Hola9:
            <br />
          </p>
          <p>
            1. Shipping &amp; Delivery Responsibility
            <br />
          </p>
          <p>
            &#61623; <b>Seller-Managed Shipping: </b>In most cases, the seller
            is responsible for shipping the product to
            <br />
            the buyer. The seller may use third-party logistics (courier
            services) for delivery, and the cost
            <br />
            and timing of delivery are typically decided by the seller.
            <br />
          </p>
          <p>
            &#61623; <b>Local Pickup or Delivery: </b>Depending on the type of
            item and the location of the buyer and
            <br />
            seller, Hola9 often encourages local transactions where the buyer
            picks up the product
            <br />
            directly from the seller, or the seller delivers it within a local
            radius.
            <br />
          </p>
          <p>
            2. Shipping Costs
            <br />
          </p>
          <p>
            &#61623; <b>Seller&#8217;s Discretion: </b>The shipping cost is
            usually determined by the seller. Sellers may either
            <br />
            include the cost of shipping in the price of the product or charge
            an additional fee.
            <br />
          </p>
          <p>
            &#61623; <b>Buyer and Seller Negotiation: </b>Shipping charges,
            delivery methods, and estimated delivery
            <br />
            times can be negotiated between the buyer and seller directly
            through the platform&#8217;s
            <br />
            communication tools.
            <br />
          </p>
          <p>
            3. Delivery Time
            <br />
          </p>
          <p>
            &#61623; <b>Varies by Seller: </b>The delivery time depends on the
            seller's location, the delivery method, and
            <br />
            the distance between the buyer and seller. Typically, for local
            transactions, delivery can
            <br />
            happen within a few days, but for longer distances, it might take
            more time.
            <br />
          </p>
          <p>
            &#61623; <b>No Standardized Time Frame: </b>Hola9 does not set a
            standard delivery time as this is largely
            <br />
            dependent on the seller's chosen method of shipment or local
            agreement with the buyer.
            <br />
          </p>
          <p>
            4. Tracking Shipments
            <br />
          </p>
          <p>
            &#61623; <b>Third-Party Couriers: </b>If the seller chooses a
            third-party courier service for delivery, the buyer
            <br />
            may be provided with a tracking number to monitor the shipment's
            progress. However, this
            <br />
            depends on the shipping service used by the seller.
            <br />
          </p>
          <p>
            &#61623; <b>No Direct Tracking by Hola9: </b>Since Hola9 does not
            manage shipping directly, the platform
            <br />
            itself does not provide tracking services. Buyers and sellers need
            to coordinate the tracking
            <br />
            of the shipment through the courier company or delivery service.
            <br />
          </p>
          <p>
            5. Delivery Issues
            <br />
          </p>
          <p>
            &#61623; <b>Handling Delivery Delays or Issues: </b>If there are
            issues with the delivery (such as delayed
            <br />
            shipments, lost items, or damaged goods), the buyer must contact the
            seller first to resolve
            <br />
            the problem. Since Hola9 is a classifieds platform, it does not take
            direct responsibility for
            <br />
            resolving delivery disputes, but it may offer support in escalating
            the issue or providing
            <br />
            communication between parties.
            <br />
          </p>
          <p>
            &#61623; <b>Refund/Return Policies: </b>If the item is not delivered
            or is significantly different from what was
            <br />
            promised, the buyer will need to refer to the seller&#8217;s refund
            or return policy. Hola9 does not
            <br />
            typically handle returns or refunds for physical products but
            encourages buyers to reach a<br />
            resolution directly with the seller.
          </p>
        </div>
      </div>
      <div>
        <div>
          <p>
            6. Shipping for Services (e.g., Home Services, Hiring)
            <br />
          </p>
          <p>
            &#61623; <b>Services Rather Than Physical Goods: </b>If the
            transaction involves hiring a service (e.g., a<br />
            plumber, electrician, tutor), there is no shipping involved.
            Instead, the service provider will
            <br />
            visit the buyer's location to perform the service.
            <br />
          </p>
          <p>
            &#61623; <b>Service Fees: </b>Service providers may charge
            additional travel fees depending on the distance
            <br />
            from their location.
            <br />
          </p>
          <p>
            7. Hola9's Role in Shipping and Delivery
            <br />
          </p>
          <p>
            &#61623; <b>Platform for Interaction: </b>Hola9&#8217;s role is
            primarily as a facilitator of communication and
            <br />
            transactions between buyers and sellers. While Hola9 allows users to
            post ads and connect
            <br />
            for buying or selling items, it does not directly manage shipping,
            delivery, or product returns.
            <br />
          </p>
          <p>
            &#61623; <b>Hola9 Express (if applicable): </b>In some cases, for
            items purchased via the Hola9 platform,
            <br />
            Hola9 may provide an express shipping or delivery service through a
            third-party courier.
            <br />
            However, this is not available for all categories or transactions.
            <br />
          </p>
          <p>
            8. Cancellation and Shipping Delay
            <br />
          </p>
          <p>
            &#61623; <b>Canceling Post-Purchase: </b>If a buyer has paid for an
            item and the shipment is delayed or the
            <br />
            item is not delivered, the buyer may request a cancellation. If the
            item has already been
            <br />
            shipped, cancellations are usually not possible unless agreed upon
            by both the buyer and
            <br />
            seller.
            <br />
          </p>
          <p>
            &#61623; <b>Seller Responsibilities: </b>Sellers are expected to
            fulfill the shipment in a timely manner as part
            <br />
            of their responsibilities. If a buyer feels the seller is not
            meeting agreed-upon shipping terms,
            <br />
            Hola9&#8217;s support team may offer assistance, but the resolution
            is generally left to buyer-seller
            <br />
            negotiations.
            <br />
          </p>
          <p>
            Conclusion
            <br />
          </p>
          <p>
            &#61623; <b>Shipping and Delivery Managed by Sellers: </b>The
            shipping and delivery process on Hola9 is
            <br />
            handled primarily by the seller, including the determination of
            shipping methods, costs, and
            <br />
            timelines.
            <br />
          </p>
          <p>
            &#61623; <b>Local Transactions Preferred: </b>For many items, Hola9
            encourages local transactions where
            <br />
            buyers can pick up the item directly or negotiate local delivery
            with the seller.
            <br />
          </p>
          <p>
            &#61623; <b>Hola9&#8217;s Limited Role: </b>Hola9 does not directly
            manage shipping, but provides a platform for
            <br />
            buyers and sellers to communicate and arrange these details
            themselves.
            <br />
          </p>
          <p>
            For specific details on shipping policies for your transaction, it's
            best to check the
            <br />
            product listing or contact the seller directly through the Hola9
            platform.
          </p>
        </div>
      </div>
    </>
  );
};

export default Shippingpolicy;
